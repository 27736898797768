
import Vue from 'vue';
import debounce from 'lodash/debounce';

export default Vue.extend({
  name: 'AutoFillHeight',

  data() {
    return {
      parentHeight: 0 as number | string,
    };
  },

  computed: {
    parentHeightNumber(): number {
      return parseInt(String(this.parentHeight), 10);
    },
  },

  watch: {
    parentHeightNumber: {
      immediate: true,
      handler(newValue) {
        this.$emit('update:height', newValue);
      },
    },
  },

  created() {
    this.onResizeDebounced = debounce(this.onResize, 300);
  },

  mounted() {
    window.addEventListener('resize', this.onResizeDebounced);
    this.updateParentHeightValue();
  },

  destroyed() {
    window.removeEventListener('resize', this.onResizeDebounced);
  },

  methods: {
    onResizeDebounced: () => {},
    onResize() {
      this.parentHeight = 0;
      setTimeout(() => this.updateParentHeightValue());
    },
    updateParentHeightValue() {
      if (this.$el.parentElement) {
        this.parentHeight = getComputedStyle(this.$el.parentElement as Element, null).height;
      }
    },
  },
});

