export enum RatingStatusAliases {
  PENDING = 'pending',
  REJECTED = 'rejected',
  RUNNING = 'running',
  DONE = 'done',
}
export interface RatingStatusData {
  icon?: string;
  color?: string;
  rateActionName: string;
}

export const RATING_STATUS_DATA: Record<RatingStatusAliases, RatingStatusData> = {
  [RatingStatusAliases.PENDING]: {
    icon: 'fa fa-clock',
    color: 'tt-light-yellow',
    rateActionName: 'rate',
  },
  [RatingStatusAliases.REJECTED]: {
    icon: 'fa fa-times-circle',
    color: 'tt-light-orange',
    rateActionName: 'rateAnyway',
  },
  [RatingStatusAliases.RUNNING]: {
    icon: 'fa fa-stopwatch',
    color: 'tt-light-blue',
    rateActionName: 'rate',
  },
  [RatingStatusAliases.DONE]: {
    icon: 'fa fa-check-circle',
    color: 'tt-light-green vibrant',
    rateActionName: 'changeRating',
  },
};
