import {
  V1EntitiesCouplesPublicExpertIndexCouple,
  V1EntitiesCouplesPublicExpertIndex,
  V1EntitiesCouplesPublicExpertIndexGroup,
  V1EntitiesExpertListsPublicIndex,
  V1EntitiesExpertListsPublicIndexExpertList,
  V1EntitiesExpertListsPublicIndexCouple,
} from '@/services/api/tsxass';

export interface GroupCouples {
  group: V1EntitiesCouplesPublicExpertIndexGroup;
  couples: V1EntitiesCouplesPublicExpertIndexCouple[];
}

export const joinExpertGroupsWithCouples = (couplesData: V1EntitiesCouplesPublicExpertIndex): GroupCouples[] => {
  const groupCouplesMap: Record<string, V1EntitiesCouplesPublicExpertIndexCouple[]> = Object.fromEntries(
    couplesData.groups.map((group) => [group.id, []]),
  );
  couplesData.couples.forEach((couple) => groupCouplesMap[couple.groupId].push(couple));

  return couplesData.groups.map((group) => ({ group, couples: groupCouplesMap[group.id] }));
};

export interface ExpertGroupCouples {
  group: V1EntitiesExpertListsPublicIndexExpertList,
  couples: V1EntitiesExpertListsPublicIndexCouple[],
}

export const joinExpertListsWithCouples = (couplesData: V1EntitiesExpertListsPublicIndex): ExpertGroupCouples[] => {
  const groupCouplesMap: Record<string, V1EntitiesExpertListsPublicIndexCouple[]> = Object.fromEntries(
    couplesData.expertsLists.map((group) => [group.id, []]),
  );
  couplesData.couples.forEach((couple) => groupCouplesMap[couple.groupId].push(couple));

  return couplesData.expertsLists.map((group) => ({ group, couples: groupCouplesMap[group.id] }));
};
