
import Vue, { PropType } from 'vue';
import { getImageUrl } from '@/helpers/getImageUrl';

interface UserData {
  userId: string,
  firstName: string,
  lastName: string,
  middleName: string,
  sex: string,
  staffPositionMain: string,
  teamsWithDelimiter?: string,
}

export default Vue.extend({
  name: 'UserListItem',

  props: {
    user: {
      type: Object as PropType<UserData>,
      default: () => ({} as UserData),
    },
    showTeams: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    name(): string {
      return `${this.user.lastName} ${this.user.firstName}`;
    },
    avatarUrl(): string {
      const imageMeta = {
        uuid: this.user.userId,
        type: 'user',
        name: 'avatar',
        size: null,
      };
      return getImageUrl(imageMeta) || '';
    },
    avatarText(): string {
      return `${this.user.lastName[0]}${this.user.firstName[0]}`;
    },
  },
});
