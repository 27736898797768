
import Vue, { PropType } from 'vue';
import { AxiosResponse } from 'axios';
import {
  ExpertGroupId, SurveyId, PageAndLimit, UserId,
} from '@/services/api/common/types';
import { tsxassApi } from '@/services/api';
import {
  V1EntitiesAudiencesPublicSearchIndexAudience,
  V1EntitiesAudiencesPublicSearchIndex,
  V1EntitiesSurveysPublicSearchByExperts,
} from '@/services/api/tsxass';
import { DEFAULT_PAGINATION } from '@/constants/pagination';
import errorHandler from '@/helpers/errorHandler';
import UserAddingDialog from '@/components/common/UserAddingDialog.vue';

interface PaginationAndSearch {
  pagination: PageAndLimit;
  search?: string;
}

type FetchAddedUsersResult = Promise<
  | Pick<AxiosResponse<V1EntitiesAudiencesPublicSearchIndex>, 'data'>
  | AxiosResponse<V1EntitiesAudiencesPublicSearchIndex>
>;

type IamString = 'expert' | 'surveyee';

export default Vue.extend({
  name: 'ExpertGroupUserAddingDialog',

  components: {
    UserAddingDialog,
  },

  props: {
    show: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    surveyId: {
      type: Number as PropType<SurveyId>,
      required: true,
    },
    groupId: {
      type: Number as PropType<ExpertGroupId>,
      required: true,
    },
    addingLoading: {
      type: Boolean,
      default: false,
    },
    iam: {
      type: String as PropType<IamString>,
      required: true,
    },
    dialogTitle: {
      type: String,
      default: '',
    },
    dialogSubtitle: {
      type: String,
      default: '',
    },
    saveButtonText: {
      type: String,
      default(): string {
        return this.$t('save') as string;
      },
    },
    useExpertsEndpoint: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    userId: {
      type: String,
      default: undefined,
    },
    canAddExternalExperts: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      audience: [] as V1EntitiesAudiencesPublicSearchIndexAudience[],
      audiencePagination: { ...DEFAULT_PAGINATION },
      audienceLoading: false,
      addedUsers: [] as V1EntitiesAudiencesPublicSearchIndexAudience[],
      addedUsersPagination: { ...DEFAULT_PAGINATION },
      addedUsersLoading: false,
    };
  },

  computed: {
    computedShow: {
      get(): boolean {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    title(): string {
      if (this.dialogTitle) {
        return this.dialogTitle;
      }

      if (this.iam === 'expert') {
        return this.$t('requestForAssessment.surveyeesAdding') as string;
      }

      return this.$t('requestForAssessment.requestFeedbackFromEmployees') as string;
    },
  },

  methods: {
    resetState() {
      this.audience = [];
      this.audiencePagination = { ...DEFAULT_PAGINATION };
      this.audienceLoading = false;
      this.addedUsersPagination = { ...DEFAULT_PAGINATION };
      this.addedUsersLoading = false;
      this.addedUsers = [];
    },
    async loadAudiences({ pagination: { limit, page }, search }: PaginationAndSearch) {
      try {
        this.audienceLoading = true;
        const fetchAuditory = this.useExpertsEndpoint ? this.fetchExperts : this.fetchAudiences;
        const response = await fetchAuditory({ pagination: { page, limit }, search });
        const { audiences = [], experts = [], pagination } = response.data;
        this.audiencePagination = pagination;
        this.audience.splice((page - 1) * limit, this.audience.length, ...audiences, ...experts);
      } catch (err) {
        errorHandler(err);
      } finally {
        this.audienceLoading = false;
      }
    },
    async loadAddedUsers({ limit, page }: PageAndLimit) {
      try {
        this.addedUsersLoading = true;
        const response = await this.fetchAddedUsers({ limit, page });
        const { audiences = [], pagination } = response.data;
        this.addedUsersPagination = pagination;
        this.addedUsers.splice((page - 1) * limit, this.addedUsers.length, ...audiences);
      } catch (err) {
        errorHandler(err);
      } finally {
        this.addedUsersLoading = false;
      }
    },
    addUsers(userIds: UserId[]) {
      this.$emit('add', userIds);
    },
    async fetchAudiences({
      pagination: { limit, page },
      search,
    }: PaginationAndSearch): Promise<AxiosResponse<V1EntitiesAudiencesPublicSearchIndex>> {
      return tsxassApi.getV1SurveysSurveyIdAudiences(this.surveyId, this.iam, search || '_', page, limit);
    },

    async fetchExperts({
      pagination: { limit, page },
      search,
    }: PaginationAndSearch): Promise<AxiosResponse<V1EntitiesSurveysPublicSearchByExperts>> {
      return tsxassApi.getV1SurveysSurveyIdSearchByExperts(this.surveyId, search || '_', this.userId, page, limit);
    },

    async fetchAddedUsers(pagination: PageAndLimit): FetchAddedUsersResult {
      await this.$nextTick();
      return { data: { audiences: [], pagination: { ...DEFAULT_PAGINATION, ...pagination } } };
    },
  },
});
