
import Vue from 'vue';
import i18n from '@/plugins/vue-i18n';

export default Vue.extend({
  name: 'PageTitle',

  props: {
    title: {
      type: String,
      default: '',
    },
    titleClass: {
      type: String,
      default: '',
    },
    browserPageTitle: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    title: {
      handler(value) {
        if (!this.browserPageTitle) return;
        let title = i18n.t('appTitle')?.toString();
        if (value) {
          title = `${value} - ${title}`;
        }
        document.title = title;
      },
      immediate: true,
    },
  },
});
