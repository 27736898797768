
import Vue, { VueConstructor } from 'vue';
import TTView from '@/components/ui/TTView.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import SectionTitle from '@/components/common/SectionTitle.vue';
import TTFullscreenLayout from '@/components/ui/TTDialog/TTFullscreenLayout.vue';

export default Vue.extend({
  name: 'TTDialogFullscreen',

  components: {
    TTView,
    TTFullscreenLayout,
    PageTitle,
    SectionTitle,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    notScrollable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    layoutComponent(): VueConstructor<Record<never, any> & Vue> {
      return this.notScrollable ? TTFullscreenLayout : TTView;
    },
  },

  methods: {
    close() {
      this.$emit('input', false);
      this.$emit('close');
    },
  },
});
