
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import ExternalExpertAddingDialog from '@/components/userAddingDialogs/ExternalExpertAddingDialog.vue';
import { ExpertGroupId, UserId } from '@/services/api/common/types';
import { PostV1Invitees } from '@/services/api/tsxass';

export default Vue.extend({
  name: 'AddExternalExpertsBtn',
  components: {
    ExternalExpertAddingDialog,
  },
  props: {
    groupId: {
      type: Number as PropType<ExpertGroupId>,
      default: -1,
    },
    userId: {
      type: String as PropType<UserId>,
      default: undefined,
    },
    addedUsers: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
  },
  data() {
    return {
      isShowExternalExpertAddingDialog: false,
    };
  },
  computed: {
    ...mapState('engagementInfo', {
      user: 'user',
    }),
  },
  methods: {
    onSubmit(user: PostV1Invitees) {
      this.$emit('add', [
        {
          ...user,
          userId: this.userId,
        },
      ]);
      this.isShowExternalExpertAddingDialog = false;
    },
  },
});
