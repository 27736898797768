
import Vue from 'vue';
import TTActionDialog from '@/components/ui/TTDialog/TTActionDialog.vue';

export default Vue.extend({
  name: 'DialogRejectedComment',

  components: {
    TTActionDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    comment: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      commentValue: '',
    };
  },

  computed: {
    title(): string {
      return this.comment
        ? this.$t('userForRate.assessmentReject.youRejectedToRate') as string
        : this.$t('userForRate.assessmentReject.youAreGoingToReject') as string;
    },
    description(): string {
      return this.comment
        ? this.$t('userForRate.assessmentReject.yourComment') as string
        : this.$t('userForRate.assessmentReject.commentWhy') as string;
    },
  },

  watch: {
    show: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.commentValue = this.comment;
        }
      },
    },
  },

  methods: {
    closeDialog() {
      this.$emit('update:show', false);
    },
    submit() {
      this.$emit('submit', this.commentValue);
      this.closeDialog();
    },
  },
});
