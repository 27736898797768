
import Vue, { PropType } from 'vue';

interface IconData {
  icon: string,
  color: string,
}

export default Vue.extend({
  name: 'TimeToEndOfStage',

  props: {
    dateEnd: {
      type: [String, null] as PropType<string | null>,
      default: '',
    },
    isStage: {
      type: Boolean,
      default: false,
    },
    isSurvey: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isCompleted(): boolean {
      return !!this.dateEnd && this.$dayjs(this.dateEnd).isBefore(this.$dayjs());
    },
    timeToEnd(): string {
      if (!this.dateEnd || this.isCompleted) {
        return '';
      }

      return this.$dayjs(this.dateEnd).fromNow(true);
    },
    labelText(): string {
      if (this.isCompleted) {
        return this.isStage
          ? this.$t('assessmentCouples.stageCompleted') as string
          : this.$t('assessmentCouples.surveyCompleted') as string;
      }

      if (!this.timeToEnd) {
        return '';
      }

      return this.isStage
        ? this.$t('assessmentCouples.timeToEndOfStage', { time: this.timeToEnd }) as string
        : this.$t('assessmentCouples.timeToEndOfSurvey', { time: this.timeToEnd }) as string;
    },
    iconData(): IconData {
      if (this.isCompleted) {
        return {
          icon: 'fal fa-check-circle',
          color: 'tt-light-green vibrant',
        };
      }

      return {
        icon: 'fal fa-clock',
        color: 'tt-blue',
      };
    },
  },
});
