
import Vue, { PropType } from 'vue';
import { Location } from 'vue-router';
import RouteNames from '@/plugins/vue-router/routeNames';

export default Vue.extend({
  name: 'BannerAskForFeedback',

  inject: ['RouteNames'],

  props: {
    short: {
      type: Boolean,
      default: false,
    },
    askForFeedbackRoute: {
      type: [Object, null] as PropType<Location | null>,
      default: () => ({ name: RouteNames.R_APP_ASK_FOR_ASSESSMENT }),
    },
  },

  computed: {
    isFull(): boolean {
      return !this.short;
    },
  },
});
