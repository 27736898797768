
import Vue, { PropType } from 'vue';
import { UserCardColumnNames } from '@/constants/userForRateColumns';
import {
  V1EntitiesAudiencesPublicSearchIndexAudience,
} from '@/services/api/tsxass';
import UserAvatar from '@/components/common/UserAvatar.vue';
import { UserCardColumn } from './types';

export default Vue.extend({
  name: 'UserCard',

  components: {
    UserAvatar,
  },

  props: {
    user: {
      type: Object as PropType<V1EntitiesAudiencesPublicSearchIndexAudience>,
      required: true,
    },
    columns: {
      type: Array as PropType<UserCardColumn[]>,
      required: true,
    },
  },

  data() {
    return {
      UserCardColumnNames,
    };
  },

  computed: {
    gridTemplateColumns(): string {
      const res = this.columns.reduce((acc, col) => {
        acc.push('width' in col ? String(col.width) : 'auto');
        return acc;
      }, [] as string[]);
      return res.join(' ');
    },
    cssVars(): Record<string, any> {
      return {
        '--grid-template-columns': this.gridTemplateColumns,
        '--grid-template-rows': `repeat(${this.columns.length}, min-content)`,
      };
    },
  },
});
