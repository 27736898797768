
import Vue from 'vue';

export default Vue.extend({
  name: 'TTLoader',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    opacity: {
      type: [String, Number],
      default: 0.46,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    absolute: {
      type: Boolean,
      default: true,
    },
  },
});
