
import Vue, { PropType } from 'vue';
import debounce from 'lodash/debounce';
import { UserId, PageAndLimit } from '@/services/api/common/types';
import {
  V1EntitiesAudiencesPublicSearchIndexAudience,
  V1EntitiesPaginateOutput,
  PostV1Invitees,
} from '@/services/api/tsxass';
import { DEFAULT_PAGINATION } from '@/constants/pagination';
import TTDialogFullscreen from '@/components/ui/TTDialog/TTDialogFullscreen.vue';
import NoDataStub from '@/components/common/NoDataStub.vue';
import UserListItem from '@/components/common/UserListItem.vue';
import VirtualPaginatedScroll from '@/components/common/VirtualPaginatedScroll.vue';
import TTSimpleCheckbox from '@/components/ui/TTSimpleCheckbox.vue';
import SectionTitle from '@/components/common/SectionTitle.vue';
import SkeletonList from '@/components/common/skeletons/SkeletonList.vue';
import AutoFillHeight from '@/components/common/AutoFillHeight.vue';
import AddExternalExpertsBtn from '@/components/assessmentChecklist/AddExternalExpertsBtn.vue';

interface SelectedUsersListItem {
  user: V1EntitiesAudiencesPublicSearchIndexAudience;
  canDelete?: boolean;
}

interface AudienceListItem {
  user: V1EntitiesAudiencesPublicSearchIndexAudience;
  checked: boolean;
  disabled: boolean;
}

interface IVirtualPaginatedScroll extends Vue {
  scrollToTop: () => void;
}

const MIN_SEARCH_LENGTH = 3;

export default Vue.extend({
  name: 'UserAddingDialog',

  components: {
    TTDialogFullscreen,
    NoDataStub,
    UserListItem,
    VirtualPaginatedScroll,
    TTSimpleCheckbox,
    SectionTitle,
    SkeletonList,
    AutoFillHeight,
    AddExternalExpertsBtn,
  },

  props: {
    show: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    addingLoading: {
      type: Boolean,
      default: false,
    },
    audience: {
      type: Array as PropType<V1EntitiesAudiencesPublicSearchIndexAudience[]>,
      default: () => [],
    },
    audiencePagination: {
      type: Object as PropType<V1EntitiesPaginateOutput>,
      default: () => ({ ...DEFAULT_PAGINATION }),
    },
    audienceLoading: {
      type: Boolean,
      default: false,
    },
    addedUsers: {
      type: Array as PropType<V1EntitiesAudiencesPublicSearchIndexAudience[]>,
      default: () => [],
    },
    addedUsersPagination: {
      type: Object as PropType<V1EntitiesPaginateOutput>,
      default: () => ({ ...DEFAULT_PAGINATION }),
    },
    addedUsersLoading: {
      type: Boolean,
      default: false,
    },
    saveButtonText: {
      type: String,
      default(): string {
        return this.$t('save') as string;
      },
    },
    hideContent: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    // С событием @add отдавать объекты пользователей, а не только их id
    emitUserObjects: {
      type: Boolean,
      default: false,
    },
    // Начальное значение списка пользователей в списке слева.
    // Вариант применения: редактирование списка пользователей без пагинации.
    initialUsersToAdd: {
      type: Array as PropType<V1EntitiesAudiencesPublicSearchIndexAudience[]>,
      default: () => [],
    },
    canAddExternalExperts: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: Number,
      default: -1,
    },
    userId: {
      type: String as PropType<String | undefined>,
      default: undefined,
    },
  },

  data() {
    return {
      audienceSearch: '',
      // Пользователи, отмеченные галочкой в правом списке
      selectedUsers: [] as V1EntitiesAudiencesPublicSearchIndexAudience[],
      // Пользователи, которые будут добавлены после применения изменений (добавленные в левый список)
      usersToAdd: [] as V1EntitiesAudiencesPublicSearchIndexAudience[],
      // Пользователи, выбранные через модалку внешних экспертов
      externalUsersToAdd: [] as Array<V1EntitiesAudiencesPublicSearchIndexAudience & PostV1Invitees>,
    };
  },

  computed: {
    // Пользователи в левом списке (добавляемые в данный момент + уже добавленные ранее)
    allSelectedUsers(): SelectedUsersListItem[] {
      return this.usersToAdd
        .map((user) => ({ user, canDelete: true }))
        .concat(this.addedUsers.map((user) => ({ user, canDelete: false })))
        .concat(this.externalUsersToAdd.map((user) => ({ user, canDelete: true })));
    },
    audienceTotalLeft(): number {
      return this.audiencePagination.total - this.audiencePagination.page * this.audiencePagination.limit;
    },
    showMoreButtonLabel(): string {
      if (!this.audiencePagination.limit) {
        return '';
      }

      const count = Math.min(this.audiencePagination.limit, this.audienceTotalLeft);
      return this.$t('expertGroups.showMore', { count, total: this.audienceTotalLeft }) as string;
    },
    usersToAddIds(): UserId[] {
      return this.usersToAdd.filter((user) => !!user.userId).map((user) => user.userId!);
    },
    selectedUserIds(): UserId[] {
      return this.selectedUsers.filter((user) => !!user.userId).map((user) => user.userId!);
    },
    audienceListItems(): AudienceListItem[] {
      return this.audience
        .filter((user) => !!user.userId)
        .map((user) => ({
          user,
          checked: this.selectedUserIds.includes(user.userId!) || user.selected,
          disabled: this.usersToAddIds.includes(user.userId!) || user.selected,
        }));
    },
  },

  watch: {
    audienceSearch(newValue: string, oldValue: string) {
      if (newValue.length < MIN_SEARCH_LENGTH && oldValue.length < MIN_SEARCH_LENGTH) {
        return;
      }
      this.searchAudiences();
    },
    show: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.loadData();
          return;
        }

        this.resetState();
      },
    },
    initialUsersToAdd: {
      immediate: true,
      handler() {
        this.usersToAdd = [...this.initialUsersToAdd];
        this.externalUsersToAdd = [];
      },
    },
  },

  created() {
    this.searchAudiences = debounce(this.searchAudiences, 500);
  },

  methods: {
    searchAudiences() {
      const resetPagination = { ...this.audiencePagination, page: 1 };
      this.$emit('update:audience-pagination', resetPagination);
      this.loadAudiences(resetPagination);
      if (this.$refs.addedUsersList) {
        const usersList = this.$refs.audienceUsersList as IVirtualPaginatedScroll;
        usersList.scrollToTop();
      }
    },
    async loadAudiences(pagination: PageAndLimit) {
      let search = this.audienceSearch;
      if (search.length < MIN_SEARCH_LENGTH && search.length > 0) {
        search = '';
      }
      this.$emit('load-audiences', { pagination, search });
    },
    async loadAddedUsers(pagination: PageAndLimit) {
      this.$emit('load-added-users', pagination);
    },
    deleteAddedUser(user: V1EntitiesAudiencesPublicSearchIndexAudience) {
      let index = this.usersToAdd.findIndex((item) => item.userId === user.userId);
      if (index > -1) {
        this.usersToAdd.splice(index, 1);
      }
      index = this.externalUsersToAdd.findIndex((item) => item.userId === user.userId);
      if (index > -1) {
        this.externalUsersToAdd.splice(index, 1);
      }
    },
    showMoreAudience() {
      this.loadAudiences({
        limit: this.audiencePagination.limit,
        page: this.audiencePagination.page + 1,
      });
    },
    onSelectedUsersChange(value: boolean, user: V1EntitiesAudiencesPublicSearchIndexAudience) {
      if (value) {
        this.selectedUsers.push(user);
        return;
      }

      const index = this.selectedUsers.findIndex((item) => item.userId === user.userId);
      if (index > -1) {
        this.selectedUsers.splice(index, 1);
      }
    },
    addSelectedUsers() {
      this.usersToAdd.push(...this.selectedUsers);
      this.selectedUsers = [];
      if (this.$refs.addedUsersList) {
        const usersList = this.$refs.addedUsersList as IVirtualPaginatedScroll;
        usersList.scrollToTop();
      }
    },
    addExternalExperts(users: PostV1Invitees[]) {
      const dummyText = this.$t('externalExpertAddingDialog.externalExpert') as string;
      this.externalUsersToAdd.push(
        ...users.map((user) => ({
          ...user,
          userId: user.userId || user.email,
          middleName: dummyText,
          sex: dummyText,
          staffPositionMain: dummyText,
          teamsWithDelimiter: dummyText,
        })),
      );
      this.selectedUsers = [];
      if (this.$refs.addedUsersList) {
        const usersList = this.$refs.addedUsersList as IVirtualPaginatedScroll;
        usersList.scrollToTop();
      }
    },
    resetState() {
      this.$emit('reset');
      this.audienceSearch = '';
      this.selectedUsers = [];
      this.usersToAdd = [...this.initialUsersToAdd];
      this.externalUsersToAdd = [];
    },
    onCancel() {
      this.$emit('update:show', false);
    },
    onSubmit() {
      if (this.emitUserObjects) {
        this.$emit('add', this.usersToAdd);
      } else {
        this.$emit('add', this.usersToAddIds);
      }
      this.$emit(
        'add-external-experts',
        this.externalUsersToAdd.map((user) => ({
          ...user,
          userId: this.userId,
        })),
      );
    },
    loadData() {
      this.loadAudiences(this.audiencePagination);
      this.loadAddedUsers(this.addedUsersPagination);
    },
    addSelectedUsersAndSubmit() {
      this.addSelectedUsers();
      this.onSubmit();
    },
  },
});
