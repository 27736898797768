
import Vue, { PropType } from 'vue';

interface Options {
  root?: HTMLElement,
  rootMargin?: string,
  threshold?: number,
}

export default Vue.extend({
  name: 'TTIntersectionObserver',

  props: {
    options: {
      type: Object as PropType<Options>,
      default: () => ({}),
    },
  },

  data: () => ({
    observer: null as IntersectionObserver | null,
  }),

  mounted() {
    const options = this.options || {};
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit('appear');
      } else {
        this.$emit('dissapear');
      }
    }, options);

    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer!.disconnect();
  },
});
