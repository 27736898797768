
import Vue, { PropType } from 'vue';
import debounce from 'lodash/debounce';
import TTIntersectionObserver from '@/components/common/TTIntersectionObserver.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import { DEFAULT_PAGINATION } from '@/constants/pagination';

interface Pagination {
  page: number;
  limit: number;
  total: number;
}

export default Vue.extend({
  name: 'VirtualPaginatedScroll',

  components: {
    TTIntersectionObserver,
    TTLoader,
  },

  props: {
    items: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => ({ ...DEFAULT_PAGINATION }),
    },
    height: {
      type: [String, Number],
      default: 300,
    },
    autoFillHeight: {
      type: Boolean,
      default: false,
    },
    itemHeight: {
      type: [String, Number],
      required: true,
    },
    gap: {
      type: [String, Number],
      default: 0,
    },
    bench: {
      type: [String, Number],
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // Виден ли лоадер внизу списка. (Если нет - выводим лоадер поверх всего списка)
      loaderIsVisible: false,
      parentHeight: 0 as number | string,
      timeoutId: null as ReturnType<typeof setTimeout> | null,
    };
  },

  computed: {
    totalPages(): number {
      return this.pagination.total ? Math.ceil(this.pagination.total / this.pagination.limit!) : 0;
    },
    currentPageIsLast(): boolean {
      return this.totalPages === 0 || this.pagination.page === this.totalPages;
    },
    itemHeightWithGap(): number {
      return Number(this.itemHeight || 0) + Number(this.gap);
    },
    virtualScrollElement(): HTMLElement | null {
      if (!this.$refs.virtualScroll) {
        return null;
      }

      return (this.$refs.virtualScroll as Vue).$el as HTMLElement;
    },
    listHeight(): number | string {
      return this.autoFillHeight ? this.parentHeight : this.height;
    },
  },

  watch: {
    items(newValue, oldValue) {
      // обновляем значение высоты родителя при первом обновлении компонента
      if (newValue.length > 0 && oldValue.length === 0) {
        this.onResize();
      }
      this.loaderIsVisible = false;
    },
  },

  created() {
    this.onResizeDebounced = debounce(this.onResize, 300);
  },

  mounted() {
    window.addEventListener('resize', this.onResizeDebounced);
    this.onResize();
  },

  destroyed() {
    window.removeEventListener('resize', this.onResizeDebounced);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },

  methods: {
    onResizeDebounced: () => {},
    onResize() {
      if (!this.autoFillHeight) {
        return;
      }
      this.parentHeight = 0;
      this.updateParentHeightValue();
    },
    updateParentHeightValue() {
      if (this.$el.parentElement) {
        this.parentHeight = (this.$el.parentElement as Element).clientHeight;
        if (this.parentHeight > window.innerHeight || this.parentHeight === 0) {
          this.timeoutId = setTimeout(() => this.updateParentHeightValue());
        }
      }
    },
    onScrollBottom() {
      this.loaderIsVisible = true;
      if (!this.currentPageIsLast) {
        this.$emit('update:pagination', { ...this.pagination, page: this.pagination.page! + 1 });
      }
    },
    scrollToTop() {
      if (!this.virtualScrollElement) {
        return;
      }

      this.virtualScrollElement.scrollTop = 0;
    },
  },
});
