
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'SkeletonListItem',

  props: {
    height: {
      type: [String, Number] as PropType<string | number>,
      default: 60,
    },
  },

  computed: {
    itemStyle(): Record<string, string> {
      return {
        height: `${this.height}px`,
        minHeight: `${this.height}px`,
      };
    },
  },
});
