
import Vue, { PropType } from 'vue';
import { UserCardColumnNames } from '@/constants/userForRateColumns';
import { V1EntitiesCouplesPublicExpertIndexCouple } from '@/services/api/tsxass';
import UserGroupTable from '@/components/common/UserGroupTable/UserGroupTable.vue';
import { UserCardColumn } from '@/components/common/UserGroupTable/types';
import { GroupCouples } from '@/helpers/joinExpertGroupsWithCouples';
import { CoupleRejectComment } from './types';
import UserForRateCard from './UserForRateCard.vue';

export default Vue.extend({
  name: 'UserForRateGroup',

  components: {
    UserForRateCard,
    UserGroupTable,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    groups: {
      type: Array as PropType<GroupCouples[]>,
      default: () => [],
    },
    couples: {
      type: Array as PropType<V1EntitiesCouplesPublicExpertIndexCouple[]>,
      default: () => [],
    },
    showAddEmployeeButton: {
      type: Boolean,
      default: false,
    },
    hideRateButton: {
      type: Boolean,
      default: false,
    },
    showRateAnywayButton: {
      type: Boolean,
      default: false,
    },
    canDeleteCouples: {
      type: Boolean,
      default: false,
    },
    canReceiveCouplesOnMove: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array as PropType<UserCardColumn[]>,
      default(): UserCardColumn[] {
        return [
          {
            name: UserCardColumnNames.EMPLOYEE,
            title: this.$t('userForRate.employee') as string,
            width: '6fr',
          },
          {
            name: UserCardColumnNames.TEAM,
            title: this.$t('userForRate.team') as string,
            width: '5fr',
          },
          {
            name: UserCardColumnNames.STATUS,
            title: this.$t('userForRate.status') as string,
            width: '4fr',
          },
          {
            name: UserCardColumnNames.ACTION,
            title: '',
            width: '5fr',
          },
        ];
      },
    },
  },

  methods: {
    onCoupleReject(coupleId: number, rejectedComment: string) {
      const payload: CoupleRejectComment = {
        coupleId,
        rejectedComment,
      };
      this.$emit('reject', payload);
    },
  },
});
