
import Vue, { PropType } from 'vue';
import TTActionDialog from '@/components/ui/TTDialog/TTActionDialog.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import debounce from 'lodash/debounce';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import isValidEmail from '@/helpers/emailValidator';
import { V1EntitiesInviteesPublicSearchInvitee } from '@/services/api/tsxass';

type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => void;
};

const INITIAL_FORM_VALUE = {
  email: '',
  lastName: '',
  firstName: '',
};

export default Vue.extend({
  name: 'ExternalExpertAddingDialog',

  components: {
    TTActionDialog,
    TTLoader,
  },

  inject: ['RouteNames'],

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    expertListId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: undefined,
    },
    addedUsers: {
      type: Array as PropType<V1EntitiesInviteesPublicSearchInvitee[]>,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      formData: { ...INITIAL_FORM_VALUE },
      existingExternalExpert: null as V1EntitiesInviteesPublicSearchInvitee | null,
      errorMessages: [] as string[],
      isShowUserIsInYourCompanyWarning: false,
      isExpertAlreadyAdded: false,
    };
  },

  computed: {
    isShowDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
    validationRules() {
      return {
        email: [(v: string): boolean | string => !!v?.trim() || (this.$t('commonValidation.required') as string)],
        lastName: [(v: string): boolean | string => !!v?.trim() || (this.$t('commonValidation.required') as string)],
        firstName: [(v: string): boolean | string => !!v?.trim() || (this.$t('commonValidation.required') as string)],
      };
    },
    form(): VForm {
      return this.$refs.form as VForm;
    },
    warningText(): string {
      if (this.isShowUserIsInYourCompanyWarning) {
        return this.$t('externalExpertAddingDialog.userIsInYourCompanyWarning') as string;
      }
      if (this.isExpertAlreadyAdded) {
        return this.$t('externalExpertAddingDialog.expertIsAlreadyOnYourListWarning') as string;
      }
      return '';
    },
    isEmailValid(): boolean {
      return isValidEmail(this.formData.email);
    },
    isAllFieldsFilled(): boolean {
      return Object.values(this.formData).every(Boolean);
    },
    isSubmitDisabled(): boolean {
      return this.isEmailValid && this.isAllFieldsFilled
        ? this.isExpertAlreadyAdded || this.isShowUserIsInYourCompanyWarning
        || !this.isEmailValid : true;
    },
  },

  watch: {
    async existingExternalExpert(value) {
      if (!value) {
        this.isShowUserIsInYourCompanyWarning = false;
        this.formData = { ...INITIAL_FORM_VALUE, email: this.formData.email };
        return;
      }
      this.formData = {
        email: value.email,
        firstName: value.firstName,
        lastName: value.lastName,
      };
      await this.$nextTick();
      this.form.validate();
    },
    isShowDialog(value) {
      if (!value) {
        this.resetForm();
      }
    },
  },

  created() {
    this.findUserByEmail = debounce(this.findUserByEmail, 300);
  },

  methods: {
    validateEmail() {
      if (!this.isEmailValid && this.formData.email.length > 0) {
        this.errorMessages.push(this.$t('externalExpertAddingDialog.form.emailValidationError') as string);
      } else {
        this.errorMessages = [];
      }
    },
    async submitForm() {
      const data = {
        ...this.formData,
        expertListId: this.expertListId,
        userId: this.userId,
      };
      this.$emit('submit', data);
    },
    resetForm() {
      this.form.resetValidation();
      this.formData = { ...INITIAL_FORM_VALUE };
      this.existingExternalExpert = null;
      this.isShowUserIsInYourCompanyWarning = false;
      this.$emit('cancel');
    },
    async findUserByEmail() {
      this.cleanWarning();
      this.checkAlreadyAddedExperts();
      if (!this.isEmailValid || this.isExpertAlreadyAdded) return;
      try {
        this.existingExternalExpert = null;
        this.loading = true;
        const { email } = this.formData;
        const { data: { invitees } } = await tsxassApi.getV1InviteesSearch(email, this.expertListId, this.userId);
        // берем только первый элемент
        const [existingExternalExpert] = invitees;
        // смотрим был ли ранее выбран этот эксперт
        if (existingExternalExpert?.selected) {
          this.isExpertAlreadyAdded = true;
        } else {
          this.existingExternalExpert = existingExternalExpert;
        }
      } catch (err: any) {
        if (err.response.status === 409) {
          this.isShowUserIsInYourCompanyWarning = true;
          console.warn(err);
        } else {
          errorHandler(err);
        }
      } finally {
        this.loading = false;
      }
    },
    cleanWarning() {
      this.isExpertAlreadyAdded = false;
      this.isShowUserIsInYourCompanyWarning = false;
      this.errorMessages = [];
      this.form.resetValidation();
    },
    // проверка уже добавленных в список, но не отправленных экспертов (бэк о них еще не знает)
    checkAlreadyAddedExperts() {
      this.isExpertAlreadyAdded = Boolean(this.addedUsers.find(
        (user) => user.email.toLowerCase() === this.formData.email.toLowerCase(),
      ));
    },
  },
});
