
import Vue from 'vue';

export default Vue.extend({
  name: 'SectionTitle',

  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    contentClass: {
      type: String,
      default: '',
    },
  },

  computed: {
    hasTitle(): boolean {
      return !!this.title || !!this.$slots.default;
    },
    hasDescription(): boolean {
      return !!this.description || !!this.$slots.description;
    },
  },
});
