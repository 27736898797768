import { V1EntitiesPaginateOutput } from '@/services/api/tsxass';

export const DEFAULT_PAGINATION: V1EntitiesPaginateOutput = {
  page: 1,
  limit: 100,
  total: 0,
};

export default {
  DEFAULT_PAGINATION,
};
