
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import PageTitle from '@/components/common/PageTitle.vue';
import SectionTitle from '@/components/common/SectionTitle.vue';
import BannerAskForFeedback from '@/components/assessmentChecklist/BannerAskForFeedback.vue';
import { PostV1CouplesIam } from '@/services/api/tsxass';
import UserForRateGroupList from '@/components/assessmentChecklist/UserForRateGroupList.vue';
import TimeToEndOfStage from '@/components/assessmentChecklist/TimeToEndOfStage.vue';
import { SurveySettingNames } from '@/constants/SurveySettings';
import { isExternalExpert } from '@/services/cookies';
import setPageTitle from '@/helpers/setPageTitle';

export default Vue.extend({
  name: 'AssessmentCouples',

  components: {
    PageTitle,
    SectionTitle,
    BannerAskForFeedback,
    UserForRateGroupList,
    TimeToEndOfStage,
  },

  data() {
    return {
      iam: PostV1CouplesIam.EXPERT,
      isExternalExpert: isExternalExpert.get(),
    };
  },

  computed: {
    ...mapState('survey', {
      surveyMeta: 'meta',
      surveyMetaLoading: 'metaLoading',
    }),
    ...mapGetters('survey', {
      getSurveySettingValue: 'getSurveySettingValue',
    }),
    isBannerVisible(): boolean {
      return Boolean(!this.isExternalExpert && this.getSurveySettingValue(SurveySettingNames.POSSIBLE_CHOOSE_EXPERTS));
    },
    isBigScreen(): boolean {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    surveyId(): number {
      return Number(this.$route.params.surveyId);
    },
  },

  watch: {
    'surveyMeta.name': {
      handler(value) {
        setPageTitle(value);
      },
    },
  },

  mounted() {
    if (this.surveyMeta) {
      setPageTitle(this.surveyMeta.name);
    }
  },
});
