
import Vue from 'vue';

export default Vue.extend({
  name: 'TTSimpleCheckbox',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
  },
});
