
import Vue, { PropType } from 'vue';
import SkeletonListItem from './SkeletonListItem.vue';

export default Vue.extend({
  name: 'SkeletonList',

  components: {
    SkeletonListItem,
  },

  props: {
    itemsCount: {
      type: [String, Number],
      default: 3,
    },
    height: {
      type: [String, Number, null] as PropType<string | number | null>,
      default: null,
    },
    gap: {
      type: [Number, String],
      default: 0,
    },
    itemProps: {
      type: Object as PropType<any>,
      default: () => {},
    },
    dividers: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    listStyle(): Record<string, string> {
      return {
        rowGap: `${this.gap}px`,
        height: this.height ? `${this.height}px` : 'auto',
      };
    },
  },
});
