
import Vue, { PropType } from 'vue';
import { RATING_STATUS_DATA, RatingStatusData, RatingStatusAliases } from '@/constants/userRatingStatuses';

export default Vue.extend({
  name: 'AssessmentStatusChip',

  props: {
    status: {
      type: String as PropType<RatingStatusAliases>,
      required: true,
    },
  },

  computed: {
    statusData(): RatingStatusData | null {
      return RATING_STATUS_DATA[this.status as RatingStatusAliases] || null;
    },
    statusText(): string {
      // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
      return this.$t(`userForRate.statuses.${this.status}`) as string;
    },
  },
});
