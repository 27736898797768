
import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { UserCardColumnNames } from '@/constants/userForRateColumns';
import errorHandler from '@/helpers/errorHandler';
import { tsxassApi } from '@/services/api';
import {
  V1EntitiesCouplesPublicExpertIndexGroup,
  V1EntitiesCouplesPublicExpertIndexCouple,
  PostV1CouplesIam,
} from '@/services/api/tsxass';
import { joinExpertGroupsWithCouples, GroupCouples } from '@/helpers/joinExpertGroupsWithCouples';
import { UserId } from '@/services/api/common/types';
import UserForRateGroup from '@/components/assessmentChecklist/UserForRateGroup.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import ExpertGroupUserAddingDialog from '@/components/userAddingDialogs/ExpertGroupUserAddingDialog.vue';
import { isExternalExpert } from '@/services/cookies';
import { UserCardColumn } from '@/components/common/UserGroupTable/types';
import { CoupleRejectComment } from './types';

export default Vue.extend({
  name: 'UserForRateGroupList',

  components: {
    UserForRateGroup,
    TTLoader,
    ExpertGroupUserAddingDialog,
  },

  props: {
    columns: {
      type: Array as PropType<UserCardColumn[]>,
      default(): UserCardColumn[] {
        return [
          {
            name: UserCardColumnNames.EMPLOYEE,
            title: this.$t('userForRate.employee') as string,
            width: '6fr',
          },
          {
            name: UserCardColumnNames.TEAM,
            title: this.$t('userForRate.team') as string,
            width: '5fr',
          },
          {
            name: UserCardColumnNames.STATUS,
            title: this.$t('userForRate.status') as string,
            width: '4fr',
          },
          {
            name: UserCardColumnNames.ACTION,
            title: '',
            width: '5fr',
          },
        ];
      },
    },
    surveyId: {
      type: Number,
      required: true,
    },
    iam: {
      type: String as PropType<PostV1CouplesIam>,
      required: true,
    },
    showAddEmployeeButton: {
      type: Boolean,
      default: false,
    },
    hideRateButton: {
      type: Boolean,
      default: false,
    },
    showRateAnywayButton: {
      type: Boolean,
      default: false,
    },
    couplesRequestWithoutParam: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  data() {
    return {
      couplesLoading: false,
      couplesData: {
        groups: [] as V1EntitiesCouplesPublicExpertIndexGroup[],
        couples: [] as V1EntitiesCouplesPublicExpertIndexCouple[],
      },
      selectedGroupId: null as number | null,
      rejectingLoading: false,
      regainLoading: false,
      isAddingToGroupDialogShow: false,
      addingToGroupLoading: false,
      isExternalExpert: isExternalExpert.get(),
    };
  },

  computed: {
    ...mapState('engagementInfo', {
      user: 'user',
    }),
    groupsWithCouples(): GroupCouples[] {
      if (!this.couplesData) {
        return [];
      }

      return joinExpertGroupsWithCouples(this.couplesData).filter(
        (item) => item.couples.length > 0 || (item.group.canAddSurveyee && !this.isExternalExpert),
      );
    },
  },

  mounted() {
    this.loadCouples();
  },

  methods: {
    async loadCouples() {
      try {
        this.couplesLoading = true;

        const {
          data: { groups, couples },
        } = await tsxassApi.getV1CouplesIamExpert(
          this.surveyId,
          undefined,
          this.couplesRequestWithoutParam as 'favorite'[],
        );
        this.couplesData = { groups, couples };
      } catch (err) {
        errorHandler(err);
      } finally {
        this.couplesLoading = false;
      }
    },
    async rejectOfAssessment(payload: CoupleRejectComment) {
      try {
        this.rejectingLoading = true;
        await tsxassApi.postV1CouplesCoupleIdRejected(payload.coupleId, { comment: payload.rejectedComment });
        this.$emit('on-reject', payload.coupleId);
        this.loadCouples();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.rejectingLoading = false;
      }
    },
    async deleteCouple(coupleId: number) {
      try {
        this.rejectingLoading = true;
        await tsxassApi.deleteV1CouplesCoupleId(coupleId);
        this.loadCouples();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.rejectingLoading = false;
      }
    },
    addEmployee(groupId: number) {
      this.selectedGroupId = groupId;
      this.isAddingToGroupDialogShow = true;
    },
    async moveCouple(coupleId: number, groupId: number) {
      try {
        this.rejectingLoading = true;
        await tsxassApi.postV1CouplesCoupleIdMoveToExpertList(coupleId, { expertListId: groupId });
        this.loadCouples();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.rejectingLoading = false;
      }
    },
    async addUsersToGroup(userIds: UserId[]) {
      if (!this.selectedGroupId) {
        return;
      }

      try {
        this.addingToGroupLoading = true;
        const currentUserId = this.user?.userId;
        const meIsSurveyee = this.iam === PostV1CouplesIam.SURVEYEE;
        await tsxassApi.postV1CouplesByBatch({
          expertListId: this.selectedGroupId,
          couples: userIds.map((userId) => ({
            expertUserId: meIsSurveyee ? userId : currentUserId,
            surveyeeUserId: meIsSurveyee ? currentUserId : userId,
          })),
        });
        this.loadCouples();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.isAddingToGroupDialogShow = false;
        this.addingToGroupLoading = false;
      }
    },
    async regainCouple(coupleId: number) {
      try {
        this.regainLoading = true;
        await tsxassApi.postV1CouplesCoupleIdRegain(coupleId);
        this.loadCouples();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.regainLoading = false;
      }
    },
  },
});
