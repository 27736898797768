
import Vue, { PropType } from 'vue';
import { Location } from 'vue-router';
import { isExternalExpert } from '@/services/cookies';
import { UserCardColumnNames } from '@/constants/userForRateColumns';
import {
  V1EntitiesCouplesPublicExpertIndexCouple,
  V1EntitiesAudiencesPublicSearchIndexAudience,
  V1EntitiesCouplesPublicExpertIndexGroup,
} from '@/services/api/tsxass';
import { RATING_STATUS_DATA, RatingStatusAliases } from '@/constants/userRatingStatuses';
import UserCard from '@/components/common/UserGroupTable/UserCard.vue';
import { UserCardColumn } from '@/components/common/UserGroupTable/types';
import TTActionDialog from '@/components/ui/TTDialog/TTActionDialog.vue';
import DialogRejectedComment from './DialogRejectedComment.vue';
import AssessmentStatusChip from './AssessmentStatusChip.vue';

export default Vue.extend({
  name: 'UserForRateCard',

  components: {
    UserCard,
    DialogRejectedComment,
    AssessmentStatusChip,
    TTActionDialog,
  },

  inject: ['RouteNames'],

  props: {
    coupleData: {
      type: Object as PropType<V1EntitiesCouplesPublicExpertIndexCouple>,
      required: true,
    },
    columns: {
      type: Array as PropType<UserCardColumn[]>,
      required: true,
    },
    hideRateButton: {
      type: Boolean,
      default: false,
    },
    showRateAnywayButton: {
      type: Boolean,
      default: false,
    },
    canReceiveCouplesOnMove: {
      type: Boolean,
      default: false,
    },
    canDeleteCouples: {
      type: Boolean,
      default: false,
    },
    groups: {
      type: Array as PropType<V1EntitiesCouplesPublicExpertIndexGroup[]>,
      default: () => [],
    },
  },

  data() {
    return {
      isDialogRejectedCommentShow: false,
      isDialogMoveCoupleShow: false,
      isDialogDeleteCoupleShow: false,
      UserCardColumnNames,
      newGroupSelected: this.coupleData.groupId,
      isExternalExpert: isExternalExpert.get(),
    };
  },

  computed: {
    isMenuVisible(): boolean {
      return (
        (this.canDeleteCouples && !this.isExternalExpert)
        || this.isMoveToAvailable
        || this.isShowRefuseButton
        || (this.showRateAnywayButton && this.isRejectedCouple)
        || (!this.hideRateButton && this.isShowCommentButton)
      );
    },
    rateButtonText(): string {
      const action = this.coupleData.status && RATING_STATUS_DATA[this.coupleData.status as RatingStatusAliases]
        ? RATING_STATUS_DATA[this.coupleData.status as RatingStatusAliases].rateActionName
        : RATING_STATUS_DATA[RatingStatusAliases.PENDING].rateActionName;

      // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
      return this.$t(`userForRate.actions.${action}`) as string;
    },
    user(): V1EntitiesAudiencesPublicSearchIndexAudience {
      return this.coupleData.surveyee;
    },
    isRejectedCouple(): boolean {
      return this.coupleData.status === RatingStatusAliases.REJECTED;
    },
    isShowRefuseButton(): boolean {
      return this.coupleData.status === RatingStatusAliases.PENDING && this.coupleData.canReject;
    },
    isShowCommentButton(): boolean {
      return this.isRejectedCouple;
    },
    assessmentRoute(): Location {
      return { name: this.RouteNames.R_APP_SURVEYEE_ASSESSMENT, params: { coupleId: String(this.coupleData.id) } };
    },
    group(): V1EntitiesCouplesPublicExpertIndexGroup | undefined {
      return this.groups.find((groupItem) => groupItem.id === this.coupleData.groupId);
    },
    groupsToMoveTo(): V1EntitiesCouplesPublicExpertIndexGroup[] {
      return this.groups.filter(
        (groupItem) => groupItem.id !== this.coupleData.groupId && groupItem.canReceiveCouplesOnMove,
      );
    },
    isMoveToAvailable(): boolean {
      return !this.isExternalExpert && this.canDeleteCouples && this.groupsToMoveTo.length > 0;
    },
  },
  methods: {
    deleteCouple() {
      this.isDialogDeleteCoupleShow = false;
      this.$emit('delete');
    },
    moveCouple() {
      this.$emit('move', this.newGroupSelected);
      this.isDialogMoveCoupleShow = false;
    },
  },
});
